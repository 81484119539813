<template>
  <div class="form-container">
    <!--<div class="button-grid">
      <div class="button-pay unselect" :class="{'button-pay-selected': value.pg_type === 'payple'}"
           @click="value.pg_type='payple'">
        <div class="checked-button" v-if="value.pg_type==='payple'">
          <img src="/static/icon/u_check.svg" class="svg-white">
        </div>
        <div class="text-center">
          <img src="/static/img/img_credit.png" alt="신용카드" style="width:34px">
          <div class="body2-medium sub">신용카드</div>
        </div>
      </div>
      <div class="button-pay unselect" :class="{'button-pay-selected': value.pg_type === 'npay'}"
          @click="clickNpay">
        <div class="checked-button" v-if="value.pg_type==='npay'">
          <img src="/static/icon/u_check.svg" class="svg-white">
        </div>
        <div class="text-center">
          <img src="/static/img/img_npay.png" alt="네이버페이" style="width:56px">
          <div class="body2-medium sub">네이버페이</div>
        </div>
      </div>
    </div>-->

    <!-- 기존 카드 선택 -->
    <div v-if="billList.length>0" :style="`margin-bottom:${vForm ? '40' : '80'}px`">
      <div class="subtitle7 main" style="margin-bottom:4px">결제 카드 선택</div>
      <div class="dropdown unselect sub" :class="{'dropdown-open':vDrop}" @click="vDrop = !vDrop">
        <div class="flex-between">
          <div class="dropdown-selected-item">{{ selectedLabel }}</div>
          <i class="material-icons">keyboard_arrow_{{ vDrop ? 'up' : 'down' }}</i>
        </div>
        <div class="dropdown-list" v-if="vDrop">
          <div class="dropdown-item" v-for="(item,idx) in billList"
               :key="'bill-'+idx" @click.stop.prevent="clickItem(item)">{{ `${item.card_name} ${item.num}` }}</div>
          <div class="dropdown-item" @click="clickNew">+ 새로운 카드 추가하기</div>
        </div>
      </div>
    </div>

    <!-- 신규 입력 -->
    <card-input-form v-if="vForm" :value.sync="value"></card-input-form>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import CardInputForm from "./CardInputForm";

  export default {
    name: "BillingForm",
    components: {CardInputForm},
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getUserCardBill();
    },
    props: {
      service_id: {
        type: Number,
        default: 0
      }
    },
    mounted() {
    },
    data() {
      return {
        value: {
          pg_type: 'payple',
          card_num: '',
          ex_year: '',
          ex_month: '',
          birth: ''
        },
        billList: [],
        selectedBill: undefined,
        selectedLabel: '',
        vDrop: false,
        vForm: false
      }
    },
    methods: {
      clickNew() {
        this.vForm = true;
        this.selectedBill = undefined;
        this.selectedLabel = '+ 새로운 카드 추가하기';
      },
      clickItem(item) {
        this.selectedBill = item;
        this.selectedLabel = `${item.card_name} ${item.num}`;
        this.vForm = false;
        this.vDrop = false;
      },
      getUserCardBill() {
        let url = 'launchpack/card_bill';
        if(this.service_id) {
          url += `/service/${this.service_id}`;
        }

        this.request.user.get(url).then(res=>{
          this.billList = res.data;
          if(this.billList.length>0) {
            this.clickItem(this.billList[0]);
          } else {
            this.vForm = true;
          }
        });
      },
      /*clickNpay() {
        this.toast('네이버페이 결제 방식은 아직 준비중입니다');
        // this.value.pg_type='npay';
      }*/
    }
  }
</script>


<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .button-grid
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-column-gap 20px

  .button-pay
    position relative
    padding 20px 24px
    border 1px solid #e3e3e3
    border-radius 4px
    box-shadow 0 2px 3px rgba(0, 0, 0, 0.07)
    height 96px
    display flex
    align-items center
    justify-content center

  .button-pay-selected
    border 2px solid $primary
    padding 16px 20px

  .checked-button
    width 20px
    height 20px
    border-radius 100%
    color white
    padding 2px
    background-color $primary
    position absolute
    top -10px
    right -10px

  .dropdown
    position relative
    border 1px solid $gray1
    border-radius 8px
    background-color white

  .dropdown-open
    border-bottom-left-radius 0
    border-bottom-right-radius 0

  .dropdown
  .dropdown-item
    padding 12px 16px
    font-size 15px

  .dropdown-item:hover
    background-color $gray4

  .dropdown-list
    position absolute
    background-color white
    z-index 1
    border 1px solid $gray1
    border-bottom-left-radius 8px
    border-bottom-right-radius 8px
    top 47px
    left -1px
    width calc(100% + 2px)



</style>
